// Importa 'firestore' directamente desde tu archivo de configuración de Firebase
import { firestore } from '../../firebase/firebase.js';
import { doc, updateDoc, runTransaction } from 'firebase/firestore';


const assignInitialPoints = async (user, setPointsConsumed) => {
  let points;

  // Utiliza la instancia de 'firestore' importada para acceder a Firestore
  const userRef = doc(firestore, 'users', user.uid);
  await updateDoc(userRef, { points });
  setPointsConsumed(points);  // Actualizar pointsConsumed en UserContext
};


const deductPoints = async (userUid, pointsToDeduct) => {
  console.log(`Intentando deducir puntos: ${pointsToDeduct} del usuario: ${userUid}`);
  const userRef = doc(firestore, 'users', userUid);

  try {
    const newPoints = await runTransaction(firestore, async (transaction) => {
      const userSnapshot = await transaction.get(userRef);
      if (!userSnapshot.exists()) {
        throw new Error("El usuario no existe en Firestore.");
      }

      const currentUserPoints = userSnapshot.data().points;
      console.log(`Puntos actuales del usuario: ${currentUserPoints}`);

      if (currentUserPoints < pointsToDeduct) {
        throw new Error('Puntos insuficientes.');
      }

      const newPoints = currentUserPoints - pointsToDeduct;
      transaction.update(userRef, { points: newPoints });

      console.log(`Puntos deducidos: ${pointsToDeduct}. Nuevos puntos: ${newPoints}`);
      return newPoints;  // Asegurarse de que esto se devuelve
    });

    console.log('Transacción completada con éxito!');
    return newPoints; // Devolver newPoints para que sea accesible fuera de la transacción
  } catch (error) {
    console.error('Error en la transacción:', error);
    throw error; // Propagar el error para manejarlo en el componente
  }
};




export { assignInitialPoints, deductPoints };
