import React, { useContext, Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider.js';
import UserDashboard from '../users/UserDashboard.js';


// Importa tus componentes de manera dinámica para optimizar la carga
const ManualApp = lazy(() => import('../ManualApp.js'));
const AutoVozVoz = lazy(() => import('../AutoVozVoz.js'));
const VisionTranslate = lazy(() => import('../VisionTranslate.js'));
const Favorites = lazy(() => import('../Favorites.js'));
const Suscriptions = lazy(() => import('../Suscriptions.js'));
const Privacy = lazy(() => import('../Privacy.js'));
const FaqComponent = lazy(() => import('../FaqComponent.js'));
const NotFoundPage = lazy(() => import('../NotFoundPage.js')); // Componente para la página 404
const LogInOutContainer = lazy(() => import('../users/LogInOutContainer.js'));
const Footer = lazy(() => import('../Footer.js'));

function UserRoutes() {
  const { user }  = useContext(UserContext);

  return (
    <div className="main-content" style={{height:'100%!important', minHeight:'100vh!important'}}>
      <Suspense fallback={<div>Loading...</div>}>
        <LogInOutContainer />
        <Routes>
          {user && user.subscriptionStatus === 'plata' && (
            <>
              <Route path="/vision" element={<VisionTranslate />} />
              <Route path="/favorites" element={<Favorites />} />
            </>
          )}
          {user && user.subscriptionStatus === 'premium' && (
            <>
              <Route path="/manual" element={<ManualApp />} />
              <Route path="/vision" element={<VisionTranslate />} />
              <Route path="/favorites" element={<Favorites />} />
            </>
          )}
          {/* Ruta común a ambos tipos de usuarios */}
          <Route path="/" element={<AutoVozVoz />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/suscriptions" element={<Suscriptions />} />
          <Route path="/faq" element={<FaqComponent />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* Ruta para páginas no encontradas */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

export default UserRoutes;
